@import '../../../styles/vars';
@import '../../../themes/tokens';

.app-filter {
  margin-left: $spacing-11;

  .bx--dropdown {
    border-bottom: 0;
    height: $spacing-09;
    max-height: $spacing-09;
    width: $filter-dropdown-width;

    .bx--list-box__menu-item {
      height: auto;
      min-height: $spacing-08;
    }

    .bx--list-box__menu-item__option {
      align-items: center;
      display: flex;
      height: auto;
      min-height: $spacing-09;
      padding: $spacing-04 0;
      padding-right: 0;
      white-space: normal;
    }
  }

  .bx--list-box__menu {
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 1px solid $fp-primary-brand;
    }
  }

  .bx--list-box__field {
    height: 100%;

    &:focus {
      outline: none;
    }
  }
}
