@import './tokens';
@import '../styles/vars';

.bx--btn--primary {
  color: $text-01;

  &:hover {
    color: $text-01;
  }
}

.bx--btn {
  min-width: 11.25rem;
  padding-right: 1rem;
}

.bx--date-picker__input {
  font-family: $prometo-font-family;
  font-weight: 400;
}

.bx--data-table th {
  background-color: $field-01;
}

.bx--data-table td,
.bx--data-table tbody th {
  background-color: $field-02;
}

@media screen and (max-width: 820px) {
  .bx--btn {
    min-width: 8.25rem;
  }
}

@media screen and (max-width: 712px) {
  .bx--btn {
    min-width: 9.25rem !important;
  }
}

.applogo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
}

.overlaylogo {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
}
