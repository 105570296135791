@import './styles/vars';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container {
    margin: 0 auto;
    max-width: $max-app-width;
  }
}

main {
  flex: 1 1 auto;
  margin-top: $header-height;
}

.hide,
.hidden {
  display: none !important;
  visibility: hidden !important;
}
