@import '../../../../themes/tokens';
@import '../../../../styles/vars';

.accept-offer-template {
  .accept-offer__subtitle {
    font-size: $spacing-04;
  }

  .fp--heading-02 {
    padding-bottom: $spacing-05;
  }

  .accept-offer__details {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: $spacing-07;
  }

  .accept-offer__heading {
    padding-bottom: $spacing-05;
  }

  .accept-offer__heading--link {
    text-align: right;
  }

  .accept-offer__label {
    border-top: 1px solid $border-color;
    height: $spacing-07;
    padding: $spacing-03 0;
    place-self: center stretch;
    vertical-align: middle;
  }

  .accept-offer__label:nth-child(odd) {
    padding-left: $spacing-05;
  }

  .accept-offer__label--last {
    border-bottom: 1px solid $border-color;
  }

  a {
    color: $interactive-01;
    text-decoration: none;
  }

  .accept-offer__loading {
    height: 13rem;
    width: 50%;
  }

  .document-tab {
    grid-template-columns: 300px auto;
  }

  .document-tab a {
    color: #0014ff;
    text-decoration: none;
  }

  .invoice-info {
    padding-left: 40px !important;
  }
}
