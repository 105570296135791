
@import './themes/typography';
@import './themes/fleet-pool-theme';

$carbon--theme: $fp-theme;

$css--default-type: false;

@import 'carbon-components/scss/globals/scss/styles';
@import './themes/components-overwrite';

body {
  font-family: 'Prometo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.isSuperregionalParent .isSuperregional .bx--btn--secondary {
  background: #8b8b8b;
  color: #b7b4b4;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.offerconfirm textarea {
  height: 60px;
  margin-top: 8px;
  width: 100%;
}

.offerconfirm .bx--modal-container--xs {
  max-height: 60%;
}
