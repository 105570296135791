@import '../themes/tokens';
@import '../themes/typography';
@import '../styles/vars';

.components-test-page {
  .component-wrapper {
    border-bottom: 3px solid #000;
    margin: auto;
    padding: 2rem;
    text-align: left;
  }

  h1 {
    padding: 3rem 0;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .no-change-h1 {
    padding: 0;
  }

  .no-change-h3 {
    font-weight: normal;
    margin: 0;
  }

  button {
    margin-right: 1rem;
  }

  .bx--toast-notification__close-button,
  .bx--inline-notification__close-button {
    margin-right: 0;
  }

  .custom-icon {
    fill: rgb(225, 0, 0);
  }

  .input-group {
    margin-bottom: '2rem';
  }

  th {
    font-weight: bold;
  }

  td,
  th {
    padding: 0.5rem;
  }

  .bx--data-table-content {
    display: inline-block;
    margin-bottom: 30px;
    min-width: 265px;
    width: auto;
  }

  .bx--tabs--scrollable,
  .bx--tab-content {
    width: 50%;
  }

  b {
    font-weight: $font-weight-bold;
  }

  .timeline-preview {
    .timeline-list {
      width: 704px;
    }
  }

  .component-wrapper-50 {
    margin: 0 auto;
    width: 50%;
  }

  .dashboard-test {
    > h3 {
      margin-top: 2rem;
      padding: 0 2rem;
    }
  }
}

.bx--data-table th {
  padding: 0;
}

.bx--data-table {
  td,
  th {
    padding: 0 1rem;
  }
}

.bx--data-table-tall {
  td {
    padding-top: 1rem;
  }
}
