@import '../../../styles/vars';
@import '../../../themes/tokens';

.timeline-list {
  margin: $spacing-07 0 $spacing-09;

  h6 {
    border-bottom: solid 1px $fp-grey;
    line-height: normal;
    margin-bottom: $spacing-04;
    padding-bottom: $spacing-03;
  }
}
