@import '../../../styles/vars';
@import '../../../themes/tokens';

.image {
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    width: auto;
  }

  &.small {
    height: $image-small-height;
    width: $image-small-width;
  }

  &.medium {
    height: $image-medium-height;
    width: $image-medium-width;
  }

  &.large {
    height: $image-large-height;
    width: $image-large-width;
  }

  &.place-holder {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      height: 80%;
      width: 80%;
    }

    &.medium img {
      height: 50%;
      width: 50%;
    }
  }

  .caption {
    margin-top: $spacing-03;
  }
}

@media only screen and (max-width: 820px) {
  .components-vehicles-page .image.place-holder img,
  .history-wrapper .image.place-holder img {
    height: 35%;
    width: 35%;
  }
}

@media only screen and (max-width: 1279px) {
  .image.small {
    height: 75px;
    margin: 0;
    width: 75px;
  }
}
