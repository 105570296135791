@import '../../../styles/vars';
@import '../../../themes/tokens';

.process-bar-item {
  cursor: pointer;
  display: flex;
  padding: 0;

  a {
    border-bottom: 1px solid $fp-grey;
    color: $fp-text-1;
    font-weight: $font-weight-normal;
    padding: $spacing-05 $spacing-05 0;
    position: relative;
    text-decoration: none;
    transition: all 70ms linear;
    white-space: nowrap;
    width: 9rem;

    &:hover {
      border-bottom: 1px solid $fp-dark-grey;
      font-weight: $font-weight-normal;
    }

    &:focus-visible {
      border: 0;
      outline: solid 2px $fp-primary-brand;
      outline-offset: -2px;
    }
  }

  .active {
    border-bottom: 2px solid $fp-primary-brand;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    opacity: 1;
    padding: $spacing-05 $spacing-05 0.563rem;
    transition: all 70ms linear;

    &:hover {
      border-bottom: 2px solid $fp-primary-brand;
      font-weight: $font-weight-medium;
    }
  }

  &:last-of-type a {
    width: auto;
  }

  .first {
    border-bottom: 2px solid $fp-primary-brand;
    font-weight: $font-weight-medium;
    margin-bottom: 0;

    &:hover {
      border-bottom: 2px solid $fp-primary-brand;
      font-weight: $font-weight-medium;
    }
  }

  .inactive {
    &.first {
      border-bottom: 1px solid $fp-grey;
    }
  }

  &:first-of-type a {
    &.first {
      border-bottom: 2px solid $fp-primary-brand;
      font-weight: $font-weight-medium;
      margin-bottom: 0;
    }
  }

  .disabled {
    border-bottom: 1px solid $fp-grey;
    color: $fp-text-2;
    cursor: default;

    &:hover {
      border-bottom: 1px solid $fp-grey;
    }
  }

  span {
    display: inline-block;
    margin-left: $spacing-02;
    position: relative;
    vertical-align: middle;
  }

  sup {
    color: $fp-primary-brand;
    position: absolute;
    right: -$spacing-03;
    top: -$spacing-03;
  }
}

@media screen and (max-width: 767px) {
  .app-filter {
    margin-left: 1rem !important;
  }
}

@media screen and (max-width: 820px) {
  .process-bar-item a {
    max-width: 100px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5rem !important;
  }

  .app-filter {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .process-bar-item a {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 7rem;
  }

  .app-filter {
    margin-left: 1rem;
  }
}
