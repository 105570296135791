@import '../../../themes/tokens';
@import '../../../styles/vars';
@import '../../../themes/default-theme';
@import '../../../themes/ald-theme';

header {
  background-color: $fp-text-1 !important;

  .container {
    display: flex;
    height: 3rem;
  }

  &.bx--header {
    display: block;
    height: 3rem;
    margin: 0 auto;
  }

  a.bx--header__name {
    padding-right: 0;
  }

  .bx--header__name--prefix {
    height: 60%;
  }

  .bx--header__nav::before {
    display: none;
  }

  .bx--header__action > svg {
    fill: $header-text-color;
  }

  .bx--header__menu-item:focus,
  .bx--header__action:focus {
    border: 2px solid transparent;
  }

  a.bx--header__menu-item {
    white-space: nowrap;
    word-break: normal;
    word-wrap: normal;
  }

  .bx--header__menu-item:hover,
  .bx--header__action:hover {
    background-color: inherit !important;
    border-bottom: 3px solid $fp-primary-brand;
    cursor: pointer;
    height: 3rem;
  }

  .page-active {
    background-color: inherit !important;
    border-bottom: 3px solid $fp-primary-brand !important;
    font-weight: bold;
    height: 3rem;
  }

  .logo_right {
    align-items: center;
    border: 2px solid transparent;
    color: #c6c6c6;
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    height: 100%;
    letter-spacing: 0;
    line-height: $spacing-01 * 9;
    padding: 0 $spacing-05;
    position: relative;
    text-decoration: none;
    transition: background-color 110ms, border-color 110ms, color 110ms;
    user-select: none;
  }
}

@media only screen and (min-width: 600px) {
  .bx--header__nav {
    display: block;
    padding: 0;
  }

  .bx--header__menu-item {
    padding: 0 4px 0 0 !important;
  }

  .bx--header__action {
    width: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .bx--header__nav {
    display: block;
  }

  .bx--header__menu-item {
    padding: 0 10px 0 0 !important;
  }
}

@media screen and (min-width: 820px) {
  .bx--header__menu-item {
    padding: 0 20px 0 0 !important;
  }
}

@media only screen and (min-width: 1000px) {
  .bx--header__menu-item {
    padding: 0 1.5rem !important;
  }
}
