@import '../../../themes/tokens';
@import '../../../styles/vars';

$animation-duration: 500ms;

.marketplace-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: $spacing-05 0 $spacing-03 0;
  width: 100%;

  .marketplace-item--info {
    border-bottom: 1px solid $decorative-01;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  .marketplace-item--info__content {
    width: 100%;
  }

  .marketplace-item--model {
    font-weight: $font-weight-medium;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .marketplace-item--details {
    color: $link-01;
    cursor: pointer;
    margin: $spacing-02 0 $spacing-05 0;
  }

  .marketplace-item--delete {
    align-self: center;
    cursor: pointer;
    justify-content: flex-end;
    margin-left: 0;
    position: relative;
    top: -0.625rem;
  }

  .bx--tooltip__trigger {
    border: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.94);
  }

  100% {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }
}

.dealerSubscriptionDisable {
  cursor: not-allowed !important;
}

.dealerSubscriptionDisable svg {
  color: #dcd6d6;
  pointer-events: none;
}

.marketplace-item--show {
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-name: show;
}

.marketplace-item--hide {
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-name: hide;
}

@media screen and (max-width: 600px) {
  .marketplace-item .marketplace-item--info {
    margin-left: -36px !important;
  }

  .marketplace-item .marketplace-item--model {
    font-size: 8px !important;
  }
}
