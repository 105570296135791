@import '../../../themes/tokens';

.svg--color path {
  color: $icon-01;
  fill: $icon-01;
}

.svg--color-link path {
  color: $interactive-01;
  fill: $interactive-01;
}

.svg--color-disabled path {
  color: $fp-grey;
  fill: $fp-grey;
}

.icon-xs {
  max-height: 1rem;
}

.icon-sm {
  max-height: 2rem;
}

.icon-lg {
  max-height: 3.563rem;
}

.icon-xl {
  max-height: 6rem;
}

.fp-overlay__close-wrapper .svg--color path {
  color: $fp-white;
  fill: $fp-white;
}

.fp-overlay__close-wrapper .svg--color {
  background-color: $fp-text-1;
}
