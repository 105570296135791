@import '../../../../themes/tokens';
@import '../../../../themes/typography';
@import '../../../../styles/vars';

.calendar-body {
  .month-year-headline {
    background-color: $ui-01;
    border-bottom: 0;
    font-size: $spacing-05;
    font-weight: $font-weight-medium;
    line-height: 0;
    margin-top: $spacing-09;
    padding: $spacing-06 $spacing-05;
    text-transform: capitalize;
    width: 100%;
  }

  .fp--productive-heading-01 {
    margin-top: $spacing-05;
  }

  .list-item__model--headline {
    margin-top: 0;
  }
}
