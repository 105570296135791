@import '../../../themes/tokens';

.vehicle-details {
  display: flex;
  flex-direction: column;

  .vehicle-details--image {
    margin: 0 auto;
  }

  .vehicle-details--info__title,
  .vehicle-details--options__title {
    color: $text-01;
    margin: $spacing-03 0;
  }

  .vehicle-details--info__content {
    border-top: 1px solid #d6d6d6;
    color: $text-02;
  }

  table {
    border-collapse: collapse;
    border-spacing: $spacing-05 $spacing-05;
    width: 100%;
  }

  .vehicle-details--info__content thead {
    display: none;
  }

  table tr {
    line-height: $spacing-07;
  }

  table td {
    padding: 0 $spacing-05;
  }

  table ul {
    line-height: $spacing-06;
  }

  .vehicle-details--options {
    margin-top: $spacing-09;
  }

  .vehicle-details--options__content {
    color: $text-01;
    column-count: 2;
    list-style-type: none;
    margin-left: $spacing-04;
  }

  .vehicle-details--options__content li {
    padding-left: $spacing-04;
    position: relative;
  }

  .vehicle-details--options__content li::before {
    content: ' - ';
    margin-left: -$spacing-04;
    position: absolute;
  }

  .buttonTopspace {
    padding-top: 15px;
  }
}
