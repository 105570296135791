@import '../../../themes/tokens';

.app-modal--container {
  .bx--modal.is-visible {
    background-color: #33333380;
  }

  .bx--modal-container--sm {
    max-width: 30rem;
  }

  .bx--modal-content {
    margin-bottom: $spacing-06;
    padding: 0 $spacing-06 $spacing-06;
  }

  .bx--modal-container {
    background-color: $ui-02;
    position: relative;

    &::after {
      background: linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
      background-color: transparent;
      bottom: $spacing-11;
      content: '';
      height: $spacing-08;
      margin: 0 $spacing-06;
      position: absolute;
      width: 90%;
    }
  }

  .bx--modal-header {
    margin-bottom: $spacing-05;
    padding-left: $spacing-06;
    padding-top: $spacing-06;
  }

  .bx--modal-header__heading {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: $spacing-04;
  }

  .bx--btn:not(:focus),
  .bx--btn:focus {
    box-shadow: none;
  }

  .bx--modal-close {
    margin-right: $spacing-04;
    margin-top: $spacing-04;
  }
}

.btn--modal__secondary--hide {
  button.bx--btn.bx--btn--secondary {
    display: none !important;
  }
}
