@import '../../../themes/tokens';
$animation-duration: 500ms;

.fp--body-short-01 {
  color: $text-01;
}

.fp--helper-text-01 {
  color: $text-05;
  height: $spacing-06;
}

.text-identification {
  font-size: 1.125rem !important;
  word-break: break-all;
  word-wrap: break-word;
}

.list-item {
  align-items: stretch;
  border-bottom: 1px solid $fp-grey;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: $spacing-06 0;

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.94);
    }

    50% {
      opacity: 0.5;
      transform: scale(0.85);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes hide {
    0% {
      transform: scale(1);
    }

    50% {
      opacity: 0.5;
      transform: scale(0.85);
    }

    100% {
      opacity: 0;
    }
  }

  &--show {
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-name: show;
  }

  &--hide {
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-name: hide;
  }

  > div {
    margin-left: $spacing-07;
    width: 100%;
  }

  &__image {
    margin-right: $spacing-06;
    max-height: 5rem;
    max-width: 8.304rem;
  }

  &__model {
    color: $text-01;
    display: flex;
    flex-direction: column;
  }

  &__model--headline {
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__model--details {
    color: $link-01;
    cursor: pointer;
    margin-top: $spacing-03;
    width: fit-content;
  }

  &__status {
    display: flex;
    flex-direction: column;
  }

  &__status--content {
    display: flex;
    flex-direction: row;
  }

  &__status--content > svg {
    margin-left: $spacing-03;
    min-width: 2rem;
  }

  &__identification {
    display: flex;
    flex-direction: column;
  }

  &__custom a {
    color: $interactive-01;
    text-decoration: none;
  }

  &__button {
    align-items: center;
    display: flex;
  }

  .bx--tooltip__trigger {
    border: 0;
  }
}

@media screen and (max-width: 600px) {
  .list-item > div {
    margin-left: 12px;
  }
}

@media screen and (max-width: 768px) {
  .list-item > div {
    margin-left: 1rem;
  }

  .list-item__image {
    max-width: 0.304rem;
  }

  .list-item__model--headline {
    text-align: start;
    white-space: normal;
  }
}

@media screen and (max-width: 820px) {
  .list-item__image {
    max-width: 0.304rem !important;
  }

  .list-item__model--headline {
    text-align: start !important;
    white-space: normal !important;
  }

  .text-size {
    width: 55px;
    word-wrap: break-word;
  }

  .list-item > div {
    width: 90px;
  }

  .list-item div.list-item__status {
    width: 105px;
  }

  .list-item__status--content svg {
    margin-left: 2px;
  }
}

@media screen and (max-width: 1279px) {
  .fp--body-short-01.tabTopspace {
    padding-top: 35px;
  }

  .list-item div.btn-size {
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .list-item__model--headline {
    text-align: left;
    white-space: normal;
  }

  .list-item > div {
    margin-left: 15px;
    margin-right: 15px;
    width: 130px;
  }
}

@media screen and (min-width: 912px) and (max-width: 1279px) {
  .list-item__model--headline {
    text-align: left;
    white-space: normal;
  }

  .list-item > div {
    margin-left: 15px;
    margin-right: 15px;
    width: 130px;
  }

  .list-identification {
    width: 200px !important;
  }
}

@media screen and (max-width: 912px) {
  .smallscreenDisplay {
    display: none;
  }

  .list-identification {
    width: 140px !important;
  }

  .list-item .fp--body-short-01 {
    margin-left: 0 !important;
  }

  .list-item .list-item__button button {
    float: right;
    position: absolute;
    right: 35px;
  }
}
