@import '../../../themes/tokens';
@import '../../../styles/vars';

.app-tab {
  .tabs-notification {
    display: inline-block;
    margin-left: $spacing-02;
    position: relative;

    sup {
      color: $fp-primary-brand;
      position: absolute;
      right: -$spacing-03;
      top: -$spacing-03;
    }
  }

  button.bx--tabs--scrollable__nav-link {
    border-bottom: 1px solid $fp-grey;
    color: $text-01;
    font-weight: $font-weight-normal;
    margin-right: 0;
    max-width: 13rem;
    min-width: 8.625rem;
    outline: 0;
    padding: $spacing-04 $spacing-05 $spacing-05;
    text-align: left;
    width: auto;
  }

  button.bx--tabs--scrollable__nav-link:focus {
    font-weight: $font-weight-medium;
    outline: 0;
  }

  &.bx--tabs--scrollable__nav-item--disabled {
    button.bx--tabs--scrollable__nav-link {
      border-bottom: 1px solid $fp-grey;
    }
  }

  &.bx--tabs--scrollable__nav-item--selected {
    button.bx--tabs--scrollable__nav-link {
      font-weight: $font-weight-medium;
      outline: 0;
    }

    button.bx--tabs--scrollable__nav-link:focus {
      font-weight: $font-weight-medium;
      outline: 0;
    }

    button.bx--tabs--scrollable__nav-link:active {
      font-weight: $font-weight-medium;
      outline: 0;
    }

    &:hover button.bx--tabs--scrollable__nav-link {
      border-bottom: 2px solid #ff8000;
      font-weight: $font-weight-medium;
    }
  }

  &.bx--tabs--scrollable__nav-item--disabled:hover {
    button.bx--tabs--scrollable__nav-link {
      border-bottom: 1px solid $fp-grey;
    }
  }

  &.bx--tabs--scrollable__nav-item:hover {
    .bx--tabs--scrollable__nav-link {
      border-bottom: 1px solid $fp-dark-grey;
      font-weight: $font-weight-normal;
    }
  }
}
