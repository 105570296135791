@import '../../../styles/vars';
@import '../../../themes/tokens';

.vehicle-list-header-container {
  height: $process-bar-height;
  margin-bottom: $spacing-11;
  position: relative;
  width: 100%;

  .vehicle-list-header {
    align-items: center;
    background-color: $fp-white;
    border-bottom: 1px solid $fp-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: $process-bar-padding;
    width: inherit;
    z-index: $process-bar-z-index;
  }

  .fixed {
    max-width: calc(100% - #{$global-cmp-l-r-padding * 2});
    padding: $process-bar-padding;
    position: fixed;
    top: $header-height;

    @media only screen and (min-width: 1584px) {
      max-width: calc(#{$max-app-width} - #{$global-cmp-l-r-padding * 2});
    }
  }

  .component-wrapper {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .vehicle-list-header-container .vehicle-list-header {
    background-color: #fff0;
    border-bottom: 1px solid #fff0;
    flex-direction: initial !important;
    padding: 1.5rem 0;
  }
}
