@import '../../../themes/tokens';

.market-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .market-items--empty {
    align-self: center;
    margin: 0 auto;
    margin-top: 10rem;
  }

  .market-items--empty__icon {
    margin: 0 auto;
    margin-bottom: $spacing-05;
    width: 7.5rem;
  }
}
