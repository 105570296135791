@import '../../../styles/vars';
@import '../../../themes/tokens';

.dashboard-item {
  background-color: $ui-background;
  padding: $spacing-07;

  .dashboard-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: $spacing-05;
    margin-bottom: $spacing-06;

    p {
      font-weight: $font-weight-medium;
    }
  }

  .dashboard-item-body {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dashboard-model-info {
    line-height: $spacing-05;
    max-width: 12.25rem;

    .dashboard-model {
      font-weight: $font-weight-medium;
      margin-bottom: $spacing-02;
      max-width: 12rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .bx--btn {
    margin-left: $spacing-07;
    margin-right: 0;
  }

  .bx--tooltip__trigger {
    border: 0;
    font-weight: $font-weight-medium;
    margin-bottom: $spacing-02;
  }
}

@media only screen and (min-width: 712px) and (max-width: 768px) {
  .dashboard-item .bx--btn {
    min-width: 4.25rem !important;
  }

  .dashboard-items.double > div:first-of-type {
    margin-right: 1rem !important;
  }

  .dashboard .dashboard-item {
    padding: 0.5rem !important;
  }

  .dashboard-item .dashboard-model-info {
    line-height: 1rem;
    max-width: 8rem;
  }

  .dashboard-item .dashboard-model-info .dashboard-model {
    text-align: left;
    white-space: normal;
  }
}

@media screen and (min-width: 820px) and (max-width: 912px) {
  .dashboard-item .bx--btn {
    min-width: 4.25rem !important;
  }

  .dashboard-items.double > div:first-of-type {
    margin-right: 1rem !important;
  }

  .dashboard-item {
    padding: 1rem !important;
  }

  .dashboard-item .dashboard-model-info {
    line-height: 1rem;
    max-width: 8rem;
  }

  .dashboard-item .dashboard-model-info .dashboard-model {
    text-align: left;
    white-space: normal;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-item {
    height: 150px;
    padding: 1.5rem !important;
  }

  .dashboard-item .dashboard-item-header {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 820px) {
  .dashboard-item {
    height: 180px !important;
  }

  .dashboard-item .dashboard-model-info .dashboard-model {
    text-align: left;
    white-space: normal;
  }
}
