@import '../../themes/tokens';
@import '../../styles/vars';

.history-wrapper {
  margin: auto;
  padding: 0 $spacing-07 $spacing-07;
  text-align: left;

  .month-year__headline {
    background-color: $ui-01;
    border-bottom: 0;
    font-size: $spacing-05;
    font-weight: $font-weight-medium;
    line-height: 0;
    margin-top: $spacing-09;
    padding: $spacing-06 $spacing-05;
    text-transform: capitalize;
    width: 100%;
  }

  .day__headline {
    margin-top: $spacing-07;
  }

  .list-items__headline {
    font-size: $spacing-04;
    font-weight: 400;
    margin-top: $spacing-05;
    padding-top: 0;
  }

  .day__values {
    border-left: 5px solid $fp-light-grey;
    padding-left: $spacing-07;
  }

  .history-filter {
    align-items: flex-end;
    background-color: $fp-white;
    border-bottom: 1px solid $fp-grey;
    display: flex;
    margin-top: $spacing-09;

    .app-filter {
      margin-left: 0;
    }
  }

  .list-items__children--empty {
    border-top: 1px solid $fp-grey;
    margin: $spacing-09 0 $spacing-12;
  }
}

@media screen and (max-width: 1279px) {
  .history-wrapper .day__values {
    padding-left: 5px;
  }

  .bx--btn {
    min-width: 100px !important;
    width: 145px !important;
  }

  .fp-overlay--visible .bx--btn {
    width: 160px !important;
  }
}
