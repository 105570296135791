.price-condition {
  .price-condition__info {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 1rem;
    max-width: 16rem;
  }

  .multi-price-condition__info {
    margin-top: 2rem;
  }
}
