@import '../../themes/tokens';

.help-settings {
  .bx--tabs--scrollable {
    margin-top: $spacing-07;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 700;
  }

  .helpcontent {
    font-size: 12px;
    line-height: 18px;
    margin: 15px 0;

    h6 {
      font-weight: bold;
      margin-bottom: $spacing-05;
    }

    p {
      margin-bottom: $spacing-03;
    }
  }

  .app-tab button.bx--tabs--scrollable__nav-link {
    text-align: left;
  }
}
