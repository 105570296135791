@import '../../../styles/vars';

.app-language {
  display: contents;
  float: right;

  .language--select {
    margin-top: 3px;
  }

  .bx--select--inline {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;

    .bx--select-input {
      background-color: transparent;
      color: $header-text-color;
      outline: 0 solid transparent !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      width: 115px;
    }
  }

  .bx--select__arrow {
    fill: #fff;
  }

  .language--flag {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 15px;
    left: 5px;
    position: absolute;
    top: 12px;
    width: 20px;

    &.en {
      background-image: url('../../../assets/icons/lang/gb.svg');
    }

    &.de {
      background-image: url('../../../assets/icons/lang/de.svg');
    }

    &.it {
      background-image: url('../../../assets/icons/lang/it.svg');
    }

    &.es {
      background-image: url('../../../assets/icons/lang/es.svg');
    }

    &.fr {
      background-image: url('../../../assets/icons/lang/fr.svg');
    }
  }
}
