@import '../../themes/tokens';

.register {
  margin-left: auto;
  margin-right: auto;
  max-width: 66%;
  padding-top: $spacing-07;

  .register__unavailable {
    text-align: center;
  }

  .register__unavailable-img {
    margin-top: 30%;
    width: 8.5rem;
  }

  h2 {
    font-weight: 400;
    margin-bottom: $spacing-07;
    margin-top: $spacing-10;
  }

  .register__unavailable-contacts {
    display: flex;
    justify-content: center;
  }

  .register__unavailable-phone,
  .register__unavailable-mail {
    padding: $spacing-05;
  }

  .register__unavailable-mail-link {
    font-size: $spacing-05;
  }

  h5 {
    margin-bottom: $spacing-07;
  }

  .register__buttons {
    margin-bottom: $spacing-10;
    margin-top: $spacing-09;
    text-align: right;

    button {
      margin-left: $spacing-02;
    }
  }

  .register__profile,
  .register__time-slots {
    position: relative;
  }

  .register__header-logo {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }
}

@media screen and (max-width: 900px) {
  .register {
    max-width: 90%;
  }
}
