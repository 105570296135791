.register-page {
  padding: 0 10.125rem;
  position: relative;

  .register-page__logo {
    left: 2rem;
    position: absolute;
    top: 1rem;
    width: 6.125rem;
  }
}
