@import '../../../themes/tokens';
@import '../../../styles/vars';

.fp-notifications-container {
  height: auto;
  padding: $spacing-05 $spacing-02;
  position: fixed;
  right: 0;
  top: $spacing-09;
  width: $notifications-container-width;
  z-index: $notifications-z-index;

  .bx--toast-notification {
    margin-left: auto;
    margin-right: auto;
  }
}
