@import '../../themes/tokens';

.account-settings {
  .bx--tabs--scrollable {
    margin-top: $spacing-07;
  }

  .bx--tab-content {
    padding-left: 0;
    padding-right: 0;
  }

  .app-tab button.bx--tabs--scrollable__nav-link {
    text-align: left;
  }
}
