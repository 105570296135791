@import 'carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/layout/breakpoint';

.image-slider {
  dds-carousel {
    --dds--carousel--page-size: 1;

    @include carbon--breakpoint(md) {
      --dds--carousel--page-size: 2;
    }

    @include carbon--breakpoint(lg) {
      --dds--carousel--page-size: 3;
    }
  }

  .image {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
