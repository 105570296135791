// Fleetpool color codes
$fp-primary-brand: #ff8000;
$fp-white: #fff;
$fp-light-grey: #f5f5f5;
$fp-grey: #d6d6d6;
$fp-dark-grey: #919191;
$fp-text-1: #333;
$fp-text-2: #6f6f6f;
$fp-text-3: #adadad;
$fp-text-5: #848484;
$fp-inverse: #515151;
$fp-red: #c64c37;
$fp-yellow: #f0c11b;
$fp-orange: #ff8d1a;
$fp-support-2: #037474;
$fp-support-4: #ea7805;
$fp-hover-primary: #f47b02;
$fp-hover-secondary: #4e4e4e;
$fp-inverse-support-1: #cf6755;
$fp-inverse-support-2: #1c8282;
$fp-inverse-link: #f93;
$fp-hover-ui: #eaeaea;
$fp-hover-danger: #b64936;
$fp-active-danger: #a14535;
$fp-active-primary: #d6700a;
$fp-active-ui: #c1c1c1;
$fp-highlight: #ffb366;
$fp-hover-selected-ui: #cdcdcd;

//-------------------------
// Carbon Theme Tokens
//-------------------------

// Core Colors
$ui-background: $fp-white;
$ui-01: $fp-light-grey;
$ui-02: $fp-white;
$ui-03: $fp-grey;
$ui-04: $fp-dark-grey;
$ui-05: $fp-text-1;
$button-separator: $fp-grey;
$decorative-01: $fp-grey;
$interactive-01: $fp-primary-brand;
$interactive-02: $fp-inverse;
$interactive-03: $fp-primary-brand;
$interactive-04: $fp-primary-brand;
$text-01: $fp-text-1;
$text-02: $fp-text-2;
$text-03: $fp-text-3;
$text-04: $fp-white;
$text-05: $fp-text-5;
$text-error: $fp-red;
$link-01: $fp-primary-brand;
$icon-01: $fp-text-1;
$icon-02: $fp-text-2;
$icon-03: $fp-white;
$field-01: $fp-light-grey;
$field-02: $fp-white;
$inverse-01: $fp-white;
$inverse-02: $fp-inverse;
$support-01: $fp-red;
$support-02: $fp-support-2;
$support-03: $fp-yellow;
$support-04: $fp-support-4;
$inverse-support-01: $fp-inverse-support-1;
$inverse-support-02: $fp-inverse-support-2;
$inverse-support-03: $fp-yellow;
$inverse-support-04: $fp-orange;
$inverse-link: $fp-inverse-link;
$overlay-01: $fp-text-1;
$danger: $fp-red;

//Interaction color
$focus: $fp-primary-brand;
$hover-primary: $fp-hover-primary;
$hover-primary-text: $fp-support-4;
$hover-secondary: $fp-hover-secondary;
$hover-tertiary: $fp-hover-primary;
$hover-ui: $fp-hover-ui;
$hover-ui-light: $fp-hover-ui;
$hover-row: $fp-hover-ui;
$hover-danger: $fp-hover-danger;
$active-primary: $fp-active-primary;
$active-secondary: $fp-text-5;
$active-tertiary: $fp-active-primary;
$active-ui: $fp-active-ui;
$active-danger: $fp-active-danger;
$selected-ui: $fp-grey;
$highlight: $fp-highlight;
$visited-link: $fp-active-primary;
$disabled-01: $fp-light-grey;
$disabled-02: $fp-active-ui;
$disabled-03: $fp-dark-grey;
$skeleton-01: $fp-hover-ui;
$skeleton-02: $fp-active-ui;
$hover-selected-ui: $fp-hover-selected-ui;
$inverse-hover-ui: $fp-hover-secondary;
$inverse-focus-ui: $fp-white;

// Spacings (in components)
$spacing-01: 0.125rem;
$spacing-02: 0.25rem;
$spacing-03: 0.5rem;
$spacing-04: 0.75rem;
$spacing-05: 1rem;
$spacing-06: 1.5rem;
$spacing-07: 2rem;
$spacing-08: 2.5rem;
$spacing-09: 3rem;
$spacing-10: 4rem;
$spacing-11: 5rem;
$spacing-12: 6rem;

// Headings
$productive-heading-01: (
  font-size: 0.875rem,
  font-weight: 500,
  line-height: 1.28572,
  letter-spacing: 0.16px,
);

$heading-01: ($productive-heading-01);

$productive-heading-02: (
  font-size: 1rem,
  font-weight: 500,
  line-height: 1.375,
  letter-spacing: 0,
);

$heading-02: ($productive-heading-02);

/*
$disabled-01: #f3f3f3;
$disabled-02: #cccccc;
$disabled-03: $local-gray-90;
$focus: $local-primary-brand;
$hover-primary: $local-red-60;
$active-primary: $local-red-60;
$hover-primary-text: $local-gray-100;
$hover-secondary: #4c4c4c;
$active-secondary: #6f6f6f;
$hover-tertiary: #0353e9;
$active-tertiary: #0530ad;
$hover-ui: none;
$active-ui: #bebebe;
$selected-ui: #dcdcdc;
$hover-selected-ui: #cacaca;
$hover-danger: #ba1b23;
$active-danger: #750e13;
$hover-row: #e5e5e5;
$visited-link: #8a3efc;
$icon-01: #1f1f1f;
$interactive-01: $local-primary-brand;
$interactive-02: $local-gray-100;
$interactive-03: $local-gray-100;
$interactive-04: $local-gray-60;
$inverse-02: $local-gray-100; // Used as notification and tooltip background
$inverse-01: $local-white;
$support-01: $local-error-crimson;

// Colors (typography)
$text-01: $local-gray-100;
$text-02: #565656;
$text-03: #8c8c8c;
$text-04: $local-white;
$link-01: $local-gray-100;
$text-error: $local-error-crimson;

// Typography
$carbon--font-weights: (
  'light': 300,
  'regular': 400,
  'semibold': 600,
);

$body-short-02: (
  // Define the font (inside of all font tokens)
  font-family: unquote("'Arial', sansserif"),
  font-size: 1rem,
  // 16px
  font-weight: 400,
  line-height: 1.125rem,
  letter-spacing: -0.32px
);

$body-short-01: (
  font-size: 0.875rem,
  // 14px
  font-weight: 400,
  line-height: 1.375rem,
  letter-spacing: -0.28px,
);

$body-long-02: (
  font-size: 1rem,
  // 16px
  font-weight: 400,
  line-height: 1.375rem,
  letter-spacing: -0.32px,
);

$body-long-01: (
  font-size: 0.875rem,
  // 14px
  font-weight: 400,
  line-height: 1.07rem,
  letter-spacing: -0.28px,
);

$label-01: (
  font-size: 0.75rem,
  // 12px
  line-height: 0.75rem,
  letter-spacing: -0.1px,
);

$caption-01: (
  font-size: 0.75rem,
  // 12px
  line-height: 0.875rem,
  letter-spacing: 0,
);

$helper-text-01: (
  font-size: 0.75rem,
  // 12px
  line-height: 0.75rem,
  letter-spacing: -0.1px,
  color: $local-gray-80,
);

// Headings
$productive-heading-01: (
  font-size: 0.875rem,
  // 14px
  font-weight: bold,
  line-height: 1.125rem,
  letter-spacing: -0.14px,
);

$productive-heading-02: (
  font-size: 1rem,
  // 16px
  font-weight: normal,
  line-height: 1.375rem,
  letter-spacing: -0.16px,
);

$productive-heading-03: (
  font-size: 1.25rem,
  // 20px
  font-weight: normal,
  line-height: 1.625rem,
  letter-spacing: -0.4px,
);

$productive-heading-04: (
  font-size: 1.75rem,
  // 28px
  font-weight: normal,
  line-height: 2.25rem,
  letter-spacing: -0.84px,
);

$productive-heading-05: (
  font-size: 2.125rem,
  // 34px
  font-weight: normal,
  line-height: 2.125rem,
  letter-spacing: -1.02px,
);

$productive-heading-06: (
  font-size: 3.875rem,
  // 62px
  font-weight: normal,
  line-height: 3.875rem,
  letter-spacing: -2.48px,
);

$productive-heading-07: (
  font-size: 5.25rem,
  // 84px
  font-weight: normal,
  line-height: 5.25rem,
  letter-spacing: -3.36px,
);

// Main navigation font used as heading-01 in theme
$heading-01: (
  font-size: 1.375rem,
  // 22px
  font-weight: bold,
  line-height: 1.625rem,
  letter-spacing: -0.66px,
  color: $local-white,
  text-decoration: none,
);



// Layout (in pages)
$layout-01: 1rem;
$layout-02: 1.5rem;
$layout-03: 2rem;
$layout-04: 3rem;
$layout-05: 4rem;
$layout-06: 5rem;
$layout-07: 6rem;

// Motion
$duration--fast-01: 70ms;
$duration--fast-02: 110ms;
$duration--moderate-01: 150ms;
$duration--moderate-02: 240ms;
$duration--slow-01: 400ms;
$duration--slow-02: 720ms;
$carbon--ease-in: cubic-bezier(0, 0, 0.38, 0.9);
$carbon--ease-out: cubic-bezier(0.2, 0, 1, 0.9);
$carbon--standard-easing: cubic-bezier(0.2, 0, 0.38, 0.9);
$transition--base: $duration--fast-02;
$transition--expansion: $duration--moderate-02;

//-------------------------
// Carbon Component Tokens
//-------------------------

// Skeleton
$skeleton-01: #e5e5e5;
$skeleton-02: #bebebe;

// Button
$button-font-weight: 400;
$button-font-size: 0.875rem;
$button-border-radius: 100px;
$button-height: 3rem;
$button-padding: calc(0.875rem - 3px) 30px calc(0.875rem - 3px) 30px;
$button-padding-field: $spacing-04 $spacing-06 0.6875rem $spacing-06;
$button-padding-sm: calc(0.375rem - 3px) 16px calc(0.375rem - 3px) 16px;
$button-padding-lg: $spacing-04;
$button-padding-ghost: calc(0.875rem - 3px) 12px;
$button-padding-ghost-field: calc(0.675rem - 3px) 12px;
$button-padding-ghost-sm: calc(0.375rem - 3px) 12px;
$button-border-width: 2px;
$button-outline-width: 1px;
$button-outline-offset: -5px;
$button-outline: 1px solid $interactive-01;

// Modal
$ui-01: $local-gray-20;
$ui-02: yellow;

// Loading (_inline-loading.scss)
$ui-03: $local-red-60;
$interactive-04: $local-white;
// Loading success
$support-02: $local-green-70;

// Loading Overlay (_loading.scss)
$overlay-01: $local-gray-60;

// Toast aka Notification (high contrast _toast-notification.scss)
// Error toast
$inverse-support-01: $local-primary-brand;
// Success toast
$inverse-support-02: $local-green-70;
// Warning toast
$inverse-support-03: $local-yellow-60; */
