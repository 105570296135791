@import '../../themes/tokens';

.vehciles-calendar {
  .filters-wrapper {
    align-items: flex-end;
    background-color: $fp-white;
    border-bottom: 1px solid $fp-grey;
    display: flex;
    margin-top: 3rem;

    .app-filter {
      margin-left: 0;
    }
  }
}
