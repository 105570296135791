@import '../../../styles/vars';

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: $footer-top-padding $global-cmp-l-r-padding $footer-bottom-padding;

  a {
    cursor: pointer;
    font-size: $footer-links-font-size;
    margin-left: $footer-link-left-margin;
  }

  .footer_links {
    text-align: right;
  }
}
