@import '../../themes/tokens';

.dashboard {
  background: url('../../assets/icons/Backgound_Logo_Cropped.svg'), $ui-01;
  background-color: $ui-01;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 17rem;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 11.75rem;
    padding: $spacing-09 $spacing-06;
  }

  h3 {
    color: $text-01;
    font-weight: normal;
  }

  &.one-item {
    flex-direction: row;

    .container {
      flex-direction: row;
      min-height: 23.375rem;
    }

    .container > div {
      width: 50%;
    }

    h3 {
      width: 40%;
    }

    .bx--modal-header__heading {
      width: 100%;
    }
  }

  &.two-items {
    h3 {
      margin-bottom: $spacing-07;
      width: 40%;
    }

    .bx--modal-header__heading {
      margin-bottom: 0;
      width: 100%;
    }

    .container {
      min-height: 23.375rem;
    }
  }
}
