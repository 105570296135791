@import '../../themes/tokens';

.vehicle-details__loading {
  div {
    margin: 0 auto;
  }
}

.vehicle-details-module {
  .vehicle-details--status {
    color: $text-01;
    display: flex;
    margin: $spacing-05 0 $spacing-07;
  }

  .vehicle-details--status svg {
    color: $text-01;
    margin-left: $spacing-01;
  }

  .vehicle-details--status div {
    padding-top: $spacing-02;
  }

  .bx--tab-content {
    padding: $spacing-05 0;
  }
}

.LogistikportalLink .list-item__button {
  display: inline-block !important;
}

.list-item__button p {
  padding: 15px 0;
}
