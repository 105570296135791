@import '../themes/tokens';
@import '../themes/typography';
@import '../styles/vars';

.components-vehicles-page {
  .component-wrapper {
    margin: auto;
    padding: 0 $spacing-07 $spacing-07;
    text-align: left;
  }

  .vehicle-list-header-container {
    .component-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}
