@import '../../../../themes/tokens';

.pick-up-appointment {
  .bx--date-picker__input {
    height: 1.6rem;
  }

  .bx--dropdown {
    height: 1.4rem;
  }

  .bx--list-box__field {
    height: 100%;
  }

  .appointment--time,
  .appointment--date {
    padding: $spacing-06 $spacing-06 $spacing-05 $spacing-06;
  }

  .bx--date-picker__icon {
    right: $spacing-08;
  }

  .bx--date-picker-input__wrapper {
    max-width: 8.5rem;
  }
}
