@import '../../../themes/tokens';
@import '../../../styles/vars';

$animation-duration: 500ms;

.release-item {
  flex-basis: 28.75%;
  margin-bottom: $spacing-10;
  margin-right: $spacing-07;

  .release-item--image {
    padding-bottom: 1rem;
  }

  .release-item--model {
    font-weight: $font-weight-medium;
    max-width: 14rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bx--btn {
    width: 100%;
  }

  .release-item--details {
    color: $link-01;
    cursor: pointer;
    margin: $spacing-02 0 $spacing-04 0;
  }

  .bx--tooltip__trigger {
    border: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.94);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.85);
  }

  100% {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }
}

.release-item--show {
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-name: show;
}

.release-item--hide {
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-name: hide;
}
