$header-height: 3rem;
$global-cmp-l-r-padding: 2rem;
$footer-links-font-size: 0.875rem;
$footer-link-left-margin: 3.125rem;
$footer-top-padding: 1.375rem;
$footer-bottom-padding: 1.563rem;
$header-text-color: #c6c6c6;

$overlay-z-index: 100;

$notifications-z-index: 101;
$notifications-container-width: 28rem;

$prometo-font-family: 'Prometo';

$image-large-width: 25rem;
$image-large-height: 14.1rem;

$image-medium-width: 9.375rem;
$image-medium-height: 5.063rem;

$image-small-width: 8.25rem;
$image-small-height: 3.75rem;

$process-bar-z-index: 99;
$process-bar-height: 0.875rem;
$process-bar-padding: 1.5rem 0 0 0;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$filter-dropdown-width: 13.75rem;

$light-grey-border: #d6d6d6;
$active-border: #ff8000;

$border-color: #e0e0e0;

$max-app-width: 99rem;
