@import '../../../styles/vars';
@import '../../../themes/tokens';

.timeline-item {
  margin: 0;
  padding-bottom: $spacing-07;
  padding-left: 1.25rem;
  position: relative;

  &::before {
    bottom: 0;
    color: $fp-primary-brand;
    content: '\2022';
    font-size: 1.3rem;
    left: 0;
    position: absolute;
    top: -0.188rem;
    z-index: 2;
  }

  &::after {
    border-left: solid 1px $fp-grey;
    bottom: 0;
    content: '';
    height: 100%;
    left: $spacing-02;
    position: absolute;
    top: $spacing-03;
    z-index: 1;
  }

  &:last-of-type {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }

  .date {
    display: inline-block;
    margin-right: $spacing-05;
    vertical-align: top;
    width: $spacing-09;
  }

  .event-title {
    display: inline-block;
    font-weight: $font-weight-medium;
  }

  .event-description {
    font-weight: $font-weight-normal;
    margin-top: $spacing-02;
  }

  .bx--accordion {
    display: inline-block;
    width: auto;
  }

  .bx--accordion__item {
    border: 0;
  }

  .bx--accordion__heading {
    height: auto;
    min-height: 0;
    padding: 0;
    width: auto;

    .bx--accordion__title {
      font-weight: $font-weight-medium;
      line-height: normal;
      margin: 0 $spacing-03 0 0;
    }

    .bx--accordion__arrow {
      margin: 0;
    }

    &:hover::before {
      background-color: transparent;
    }

    &:focus::before {
      border: 0;
    }

    &:focus-visible .bx--accordion__title {
      border-bottom: solid 2px $fp-primary-brand;
      color: $fp-primary-brand;
    }

    &:hover .bx--accordion__title {
      border-bottom: solid 1px $fp-primary-brand;
      color: $fp-primary-brand;
    }

    &:focus-visible .bx--accordion__arrow {
      fill: $fp-primary-brand;
    }

    &:hover .bx--accordion__arrow {
      fill: $fp-primary-brand;
    }
  }

  .bx--accordion__content {
    padding: $spacing-05 0 $spacing-02;
  }

  .bx--accordion__item--active {
    .bx--accordion__content {
      font-weight: $font-weight-normal;
      padding: $spacing-05 0 $spacing-02;
    }
  }

  a {
    color: $fp-primary-brand;
    font-weight: $font-weight-normal;
    text-decoration: none;
  }

  .event-link {
    margin-top: $spacing-03;

    svg {
      height: $spacing-05;
      margin-left: $spacing-02;
      vertical-align: middle;
      width: auto;
    }
  }
}
