@import '@carbon/themes/scss/themes';
@import './tokens';
@import './mixins';

//ALD color codes

$brand-color-1: #050b7f;
$brand-color-2: #0bcbfb;
$brand-color-3: #644af3;
$brand-color-4: #4391ff;
$brand-color-5: #80b4ff;
$brand-color-6: #9cebff;

// ---------------- //

@mixin ald-bg($bg) {
  background-color: $bg;
}

@mixin ald-txt-color($color) {
  color: $color;
}

@mixin ald-fill-color($color) {
  fill: $color;
}

// Wrap container of ALD theme

.theme-ald,
.theme-ald_at {
  .applogo {
    @include logo-image(url('../assets/icons/Aldlogo.png'));
  }

  .logo_right.applogo,
  .overlaylogo.ald {
    display: none;
  }

  .overlaylogo {
    @include logo-image(url('../assets/icons/ALD-white.jpg'));

    height: 100px;
  }

  header,
  .bx--btn--secondary,
  .bx--inline-notification--error,
  .bx--toast-notification--error,
  .bx--toast-notification--success {
    @include ald-bg($brand-color-1 !important);

    .page-active,
    .bx--header__menu-item:hover,
    .bx--header__action:hover {
      border-bottom: 3px solid $brand-color-2 !important;
    }

    .bx--header__name--prefix {
      height: 100%;
    }
  }

  .bx--btn--primary {
    @include ald-bg($brand-color-2 !important);
  }

  .bx--link,
  .bx--link:visited,
  .release-item .release-item--details,
  .marketplace-item .marketplace-item--details,
  .list-item__model--details,
  .vehicle-appointment .vehicle-appointment--header__right,
  .timeline-item::before,
  .appointment--tasks li::before,
  .process-bar-item sup {
    @include ald-txt-color($brand-color-2 !important);
  }

  .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link,
  .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:focus,
  .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:active,
  .process-bar-item:first-of-type a.first,
  .process-bar-item .active {
    border-bottom: 2px solid $brand-color-2 !important;
  }

  .bx--tab--overflow-nav-button:focus {
    outline: 2px solid $brand-color-2 !important;
  }

  .flatpickr-day.today.selected {
    color: #333 !important;
    font-weight: 400;
    outline: 2px solid $brand-color-2 !important;
  }

  .bx--link:focus,
  .bx--link:visited,
  .bx--link:active {
    outline: 1px solid $brand-color-2 !important;
  }

  .bx--btn--primary:focus,
  .bx--modal-close:focus {
    border-color: $brand-color-2 !important;
  }

  .svg--color-link path {
    @include ald-txt-color($brand-color-2 !important);
    @include ald-fill-color($brand-color-2 !important);
  }

  .bx--inline-notification--error {
    .bx--inline-notification__icon,
    .bx--toast-notification__icon,
    .bx--actionable-notification__icon {
      @include ald-fill-color($brand-color-2 !important);
    }
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    .bx--tooltip--bottom::after,
    .bx--assistive-text {
      @include ald-bg($brand-color-1 !important);
    }
  }

  .imprint-page a,
  .flatpickr-day.today,
  .options--container__documents--link a,
  .options--container__password--link a {
    @include ald-txt-color($brand-color-2 !important);
  }

  .flatpickr-day.today::after,
  .flatpickr-day.selected {
    @include ald-bg($brand-color-2 !important);

    color: #333 !important;
  }

  .bx--date-picker__input:focus,
  .bx--date-picker__input.bx--focused {
    outline: 2px solid $brand-color-2 !important;
  }

  svg circle {
    stroke: $brand-color-2 !important;
  }

  .flatpickr-calendar.open:focus {
    outline: 1px solid $brand-color-2 !important;
  }

  .flatpickr-day.inRange {
    background-color: $brand-color-6 !important;
  }

  .flatpickr-day:focus,
  .flatpickr-day.endRange:hover {
    outline: 2px solid $brand-color-2 !important;
    outline-color: $brand-color-2 !important;
  }

  .time-slots-list__values .time-slot--active {
    border: 2px solid $brand-color-2 !important;
  }
}
