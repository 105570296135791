@import '../../../themes/tokens';

$animation-duration: 500ms;

.list-items {
  &__headline {
    border-bottom: 1px solid $fp-grey;
    color: $text-01;
    font-weight: bold;
    margin-top: $spacing-07;
    padding: $spacing-05 0 $spacing-03;
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.94);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__children--empty {
    align-items: center;
    border-bottom: 1px solid $fp-grey;
    color: $text-02;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding: $spacing-08 0;

    &--show {
      animation-duration: $animation-duration;
      animation-fill-mode: forwards;
      animation-name: show;
    }
  }

  .loading-list-items {
    height: $spacing-09;
    width: 50%;
  }
}

@media screen and (max-width: 912px) {
  .list-item__model--headline {
    text-align: left;
    white-space: normal;
  }
}
