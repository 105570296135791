.home-list__loading {
  div {
    margin: 0 auto;
  }
}

.list-items-wrapper {
  &:last-of-type {
    margin-bottom: 60vh;
  }
}

.vehicle-list--header-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
