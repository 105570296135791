@import '../../../styles/vars';
@import '../../../themes/tokens';

.process-bar-container {
  .process-bar {
    background-color: $fp-white;
    display: flex;
    flex-direction: row;
    height: $spacing-09;
    list-style: none;
    margin: 0;
    margin-bottom: -1px;
    max-width: 100%;
    outline: 0;
    overflow: auto hidden;
    scrollbar-width: none;
    width: 100%;
    z-index: $process-bar-z-index;
  }
}
