@import '../themes/tokens';

.imprint-page {
  padding-bottom: $spacing-09;

  .fp--productive-heading-02 {
    padding: $spacing-09 0 $spacing-05 0;
  }

  p {
    line-height: 1.25rem;
  }

  a {
    color: $fp-primary-brand;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .imprintsubHead {
    font-size: 0.9rem;
    font-weight: 800;
    padding: 0;
  }
}
