@import '../../../themes/tokens';

.app-search {
  background-color: $field-01;
  border-bottom: 0;
  display: flex;
  flex-direction: row;
  height: $spacing-09;
  margin-left: $spacing-01;
  max-height: $spacing-09;
  padding: 1rem;
  width: 180px;

  input {
    background-color: $field-01;
    border: 0;
    color: $text-01;
    font-size: 0.875rem;
    outline: none;
  }
}

@media only screen and (max-width: 1279px) {
  .app-search,
  .app-filter .bx--dropdown {
    width: 120px;
  }

  .app-search input {
    width: 100%;
  }
}
