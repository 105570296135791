@import '@carbon/themes/scss/themes';
@import './tokens';
@import './mixins';

.applogo {
  @include logo-image(url('../assets/icons/fleetpool-icon-logo.svg'));
}

.overlaylogo {
  @include logo-image(url('../assets/icons/fleetpool-text-logo.svg'));

  height: 100px;
}

.theme-default {
  .imprint-container {
    h6 {
      margin-bottom: $spacing-03;
      margin-top: $spacing-05;
    }

    p {
      margin-bottom: $spacing-03;
      margin-top: $spacing-03;
    }
  }
}
