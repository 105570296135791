@import '../../../styles/vars';
@import '../../../themes/tokens';

.top-page-headline {
  background: url('../../../assets/icons/Backgound_Logo_Cropped.svg'), $ui-01;
  background-color: $ui-01;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 17rem;
  min-height: 11.75rem;

  h3 {
    padding-left: 1rem;
    padding-top: 3rem;
  }
}
