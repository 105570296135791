@import '../../themes/tokens';

.release-page {
  margin: 0 auto;
  padding: $spacing-07;

  .release-page--description {
    color: $text-01;
    margin-bottom: $spacing-08;
    max-width: 60%;
  }

  .release-page--description__text {
    margin: $spacing-05 0;
  }

  .release-page--items__title {
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  .release-page--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .release-page--items {
    height: calc(100vh - 32rem);
    min-height: 36rem;
    overflow-y: auto;
    width: 60.25%;
  }

  .release-page--market {
    background-color: $fp-light-grey;
    height: calc(100vh - 32rem);
    min-height: 36rem;
    overflow-y: auto;
    padding: 32px;
    width: 37.5%;

    &__description {
      margin-bottom: $spacing-05;
    }
  }
}

@media screen and (max-width: 768px) {
  .release-page .release-page--market {
    padding: 18px;
    width: 66%;
  }

  .release-items--empty__text {
    font-size: 80% !important;
  }

  .release-page .release-page--items {
    width: 90.25% !important;
  }
}

@media screen and (min-width: 820px) {
  .release-page .release-page--market {
    padding: 22px !important;
    width: 60% !important;
  }
}
