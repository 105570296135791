@import '../../../themes/tokens';
@import '../../../styles/vars';

$animation-duration: 500ms;

@keyframes show-overlay {
  0% {
    opacity: 0;
    right: -100%;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes hide-overlay {
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    opacity: 0;
    right: -100%;
  }
}

@keyframes hide-overlay-background {
  0% {
    right: 0;
  }

  100% {
    right: -100%;
  }
}

.fp-overlay {
  background: #33333380;
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: $overlay-z-index;

  .fp-overlay__content {
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-name: show-overlay;
    background: $fp-white;
    height: 100%;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &--container {
      padding: $spacing-08;
      padding-bottom: $spacing-11 + $spacing-09;
    }
  }

  .fp-overlay__content--hide {
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-name: hide-overlay;
  }

  @media only screen and (min-width: 1540px) {
    .fp-overlay__content--container {
      position: relative;
    }
  }

  .fp-overlay__content--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .fp-overlay__content--header__logo {
    margin-right: $spacing-07;
  }

  .fp-overlay__close-wrapper {
    cursor: pointer;
    height: $spacing-08;
    position: absolute;
    right: $spacing-07;
    text-align: right;
    top: $spacing-07;
    width: $spacing-08;
  }
}

.fp-overlay--visible {
  display: block;
}

.fp-overlay--first-load {
  display: none;
}

.fp-overlay--hidden {
  animation-delay: $animation-duration;
  animation-duration: 10ms;
  animation-fill-mode: forwards;
  animation-name: hide-overlay-background;
  display: block;
}

.fleetpool_logo {
  height: 50px;
}
