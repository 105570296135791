@import '@carbon/type/scss/styles';

.fp--caption-01 {
  @include carbon--type-style('caption-01');
}

.fp--caption-02 {
  @include carbon--type-style('caption-01');
}

.fp--label-01 {
  @include carbon--type-style('label-01');
}

.fp--label-02 {
  @include carbon--type-style('label-02');
}

.fp--helper-text-01 {
  @include carbon--type-style('helper-text-01');
}

.fp--helper-text-02 {
  @include carbon--type-style('helper-text-02');
}

.fp--body-short-01 {
  @include carbon--type-style('body-short-01');
}

.fp--body-short-02 {
  @include carbon--type-style('body-short-02');
}

.fp--body-long-01 {
  @include carbon--type-style('body-long-01');
}

.fp--body-long-02 {
  @include carbon--type-style('body-long-02');
}

.fp--code-01 {
  @include carbon--type-style('code-01');
}

.fp--code-02 {
  @include carbon--type-style('code-02');
}

.fp--heading-01 {
  @include carbon--type-style('heading-01');
}

.fp--heading-02 {
  @include carbon--type-style('heading-02');
}

.fp--heading-03 {
  @include carbon--type-style('productive-heading-03');
}

.fp--heading-04 {
  @include carbon--type-style('productive-heading-04');
}

.fp--heading-05 {
  @include carbon--type-style('productive-heading-05');
}

.fp--heading-06 {
  @include carbon--type-style('productive-heading-06');
}

.fp--heading-07 {
  @include carbon--type-style('productive-heading-07');
}

.fp--productive-heading-01 {
  @include carbon--type-style('productive-heading-01');
}

.fp--productive-heading-02 {
  @include carbon--type-style('productive-heading-02');
}

.fp--productive-heading-03 {
  @include carbon--type-style('productive-heading-03');
}

.fp--productive-heading-04 {
  @include carbon--type-style('productive-heading-04');
}

.fp--productive-heading-05 {
  @include carbon--type-style('productive-heading-05');
}

.fp--productive-heading-06 {
  @include carbon--type-style('productive-heading-06');
}

.fp--productive-heading-07 {
  @include carbon--type-style('productive-heading-07');
}

.fp--expressive-heading-01 {
  @include carbon--type-style('expressive-heading-01');
}

.fp--expressive-heading-02 {
  @include carbon--type-style('expressive-heading-02');
}

.fp--expressive-heading-03 {
  @include carbon--type-style('expressive-heading-03');
}

.fp--expressive-heading-04 {
  @include carbon--type-style('expressive-heading-04');
}

.fp--expressive-heading-05 {
  @include carbon--type-style('expressive-heading-05');
}

.fp--expressive-heading-06 {
  @include carbon--type-style('expressive-heading-06');
}

.fp--expressive-paragraph-01 {
  @include carbon--type-style('expressive-paragraph-01');
}

.fp--quotation-01 {
  @include carbon--type-style('quotation-01');
}

.fp--quotation-02 {
  @include carbon--type-style('quotation-02');
}

.fp--display-01 {
  @include carbon--type-style('display-01');
}

.fp--display-02 {
  @include carbon--type-style('display-02');
}

.fp--display-03 {
  @include carbon--type-style('display-03');
}

.fp--display-04 {
  @include carbon--type-style('display-04');
}

.fp--legal-01 {
  @include carbon--type-style('label-01');
}

.fp--legal-02 {
  @include carbon--type-style('label-02');
}

.fp--body-compact-01 {
  @include carbon--type-style('body-short-01');
}

.fp--body-compact-02 {
  @include carbon--type-style('body-short-02');
}

.fp--body-01 {
  @include carbon--type-style('body-long-01');
}

.fp--body-02 {
  @include carbon--type-style('body-long-02');
}

.fp--heading-compact-01 {
  @include carbon--type-style('heading-01');
}

.fp--heading-compact-02 {
  @include carbon--type-style('heading-02');
}

.fp--fluid-heading-03 {
  @include carbon--type-style('productive-heading-03');
}

.fp--fluid-heading-04 {
  @include carbon--type-style('productive-heading-04');
}

.fp--fluid-heading-05 {
  @include carbon--type-style('productive-heading-05');
}

.fp--fluid-heading-06 {
  @include carbon--type-style('productive-heading-06');
}

.fp--fluid-paragraph-01 {
  @include carbon--type-style('display-01');
}

.fp--fluid-quotation-01 {
  @include carbon--type-style('quotation-01');
}

.fp--fluid-quotation-02 {
  @include carbon--type-style('quotation-02');
}

.fp--fluid-display-01 {
  @include carbon--type-style('display-01');
}

.fp--fluid-display-02 {
  @include carbon--type-style('display-02');
}

.fp--fluid-display-03 {
  @include carbon--type-style('display-03');
}

.fp--fluid-display-04 {
  @include carbon--type-style('display-04');
}

h1 {
  @include carbon--type-style('productive-heading-07');
}

h2 {
  @include carbon--type-style('productive-heading-06');
}

h3 {
  @include carbon--type-style('productive-heading-05');
}

h4 {
  @include carbon--type-style('productive-heading-04');
}

h5 {
  @include carbon--type-style('productive-heading-03');
}

h6 {
  @include carbon--type-style('productive-heading-02');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Prometo.woff') format('woff');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Prometo-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Prometo-Black.woff') format('woff');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Prometo-Light.woff') format('woff');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Prometo-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Prometo';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Prometo-Bold.woff') format('woff');
}
