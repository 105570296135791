@import '../../../styles/vars';
@import '../../../themes/tokens';

.dashboard-items {
  display: flex;

  &.single {
    > div {
      width: 100%;
    }
  }

  &.double {
    flex-direction: row;
    width: 100%;

    > div {
      width: 50%;
    }

    > div:first-of-type {
      margin-right: $spacing-07;
    }
  }
}
