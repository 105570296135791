@import '../../../styles/vars';
@import '../../../themes/tokens';

.app-filter-by-date {
  margin: 0;
  margin-right: $spacing-01;

  .bx--date-picker__input {
    border-bottom: 0;
    height: $spacing-09;
  }
}
